import React from "react";
import Link from "components/Common/Link";
import ConfigForm from "components/Forms/ConfigForm";
import { AuthenticatedType, handleLogin } from "services/auth";
import { redirect } from "libs/utils";

const form = {
  name: "login",
  submitName: "ログイン",
  action: "/auth/login",
  json: true,
  fields: [
    {
      type: "input",
      subType: "email",
      name: "email",
      label: "メールアドレス",
      placeholder: "メールアドレス",
      rules: {
        required: true,
        minLength: { value: 5, message: "メールが正しくありません" },
      },
    },
    {
      type: "input",
      subType: "password",
      name: "password",
      label: "パスワード",
      placeholder: "",
      rules: {
        required: true,
        minLength: {
          value: 8,
          message: "パスワードは８桁以上を入力してください",
        },
      },
    },
    {
      type: "checkbox",
      subType: "checkbox",
      name: "remember",
      label: "次回から自動的にログイン",
      rules: {
        required: false,
      },
    },
  ],
};

type Props = {
  to?: string;
};

export default function Login({ to = "/" }: Props) {
  const onSuccess = (user: AuthenticatedType) => {
    if (handleLogin(user)) redirect(decodeURIComponent(to || "/"));
  };

  return (
    <>
      <h1 className="w-full flex m-6 text-4xl justify-center">ログイン</h1>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-8/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full p-4 shadow-lg rounded-lg bg-background-300 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <ConfigForm {...form} onSuccess={onSuccess} />
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link href="/auth/register">パスワードを忘れた方はこちら</Link>
              </div>
              <div className="w-1/2 text-right">
                <Link href="/auth/register">新規会員登録はこちら</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
