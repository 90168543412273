import React, { useEffect, useState } from "react";
import Login from "components/Auth/Login";
import Layout from "layouts/EmptyLayout";

type Props = {
  path?: string;
  to?: string;
};

export default function Page({ path, to }: Props) {
  const [toURL, setTo] = useState(to ?? "");
  useEffect(() => {
    if (!to) {
      const q = typeof window === "undefined" ? "" : window.location.search;
      const query = new URLSearchParams(q);
      setTo(query.get("to") ?? "");
    }
  }, [to]);
  return (
    <>
      <Layout title="Login" path={path}>
        <Login to={toURL} />
      </Layout>
    </>
  );
}
