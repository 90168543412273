import site from "config/site";
import { ValidationError } from "libs/api";
import { redirect } from "libs/utils";

export const isBrowser = () => typeof window !== "undefined";

export type UserType = {
  username: string;
  name: string;
  email: string;
};

export type AuthenticatedType = {
  user: UserType;
  token: string;
};

export const getToken = (): string | null => {
  if (!isBrowser()) return null;
  const token = window.localStorage.getItem(`${site.name}UserToken`);
  return token ?? null;
};

const setToken = (token: string): string | null => {
  if (isBrowser()) {
    window.sessionStorage.setItem(`${site.name}UserToken`, token);
    return token;
  } else return null;
};

export const getUser = (): UserType | null => {
  if (!isBrowser()) return null;
  const user = window.sessionStorage.getItem(`${site.name}User`);
  return user ? JSON.parse(user) : null;
};

const setUser = (user: UserType | null) => {
  if (isBrowser()) {
    window.sessionStorage.setItem(`${site.name}User`, JSON.stringify(user));
    return user;
  } else return null;
};

export const handleLogin = (auth: AuthenticatedType) => {
  if (auth && auth.user && auth.user.username && auth.token) {
    setToken(auth.token);
    return !!setUser(auth.user);
  } else {
    throw new ValidationError("Invalid credentials");
  }
  return false;
};

export const isLoggedIn = () => {
  const user = getUser();
  return user && user.username;
};

export const logout = (callback: Function) => {
  setUser(null);
  callback();
};

export const handleLogout = (e: Event) => {
  e.preventDefault();
  setUser(null);
  redirect("/");
};
